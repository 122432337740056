import { default as SearchOriginal } from './../search';

export default class NotFound extends SearchOriginal {
  constructor(props) {
    super(props);

    this.state = {
      pageLang: 'ja-jp'
    };
  }
}
